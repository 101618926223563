import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/GT_A2wI88hA">
    <SEO title="An Intro to Life - Romans: Principles" />
  </Layout>
)

export default SermonPost
